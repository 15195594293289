<template>
  <div class="church-list template-1">
    <header class="page-header container">
      <h1>{{ translations.tcChurchVisits }}</h1>
      <h4 class="church_name" v-if="!!this.$route.path.split('/')[4] && this.$route.path.split('/')[4] !== 'menu'">
        {{ churchDetail.name }}
      </h4>
    </header>
    <!-- / Page Header -->
    <camp-selector
      @camp_changed="page_load()"
      :tooltip="translations.tcTooltip"
      :i18n="translations.components"
    ></camp-selector>
    <!-- / Camp Selector -->
    <data-table
      :fields="dataTableFields"
      :items="visits"
      :includeAddButton="includeAddButton"
      :addItems="addItems"
      @addVisit="addVisit"
      :searchTerm="`visitsTerm`"
      :csvUrl="churchVisitsByCampAsCSVUrl"
      :pdfUrl="churchVisitsByCampAsPDFUrl"
      :reportName="reportName"
      :i18n="translations.components"
    ></data-table>
    <!-- / Data Tables -->
    <footer class="page-footer"></footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import DataTable from '@/components/DataTable.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'church-visits',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      secured_controls: {
        add_church_presentation_button: '2e18fcc4-b81d-4b54-804e-9d9e8932d810',
      },
      visits: [],
      dataTableFields: [],
      includeAddButton: true,
      addItems: {
        display: false,
        text: 'Add Visit',
        action: 'addVisit',
      },
      reportName: 'Visits',
    }
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getTranslations(),
        this.getComponentTranslations('data-table', 'camp-select'),
        this.getComponentTranslations(
          'common.attire',
          'common.church-service-type',
          'common.days',
          'common.meeting-status',
          'common.months',
          'common.presentation-types'
        ),
      ]).then((results) => {
        this.stripReadableText(results[2])
        const translatedText = {
          ...results[1],
          common: { ...this.translations.common },
        }
        this.$set(this.translations, 'components', translatedText)
        this.loadTranslations()
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  methods: {
    ...mapActions({
      clearSingleVisit: 'churchMinistry/clearSingleVisit',
      loadAllVisitsByCamp: 'churchMinistry/loadAllVisitsByCamp',
      loadChurchVisitsReportUrls: 'churchMinistry/loadChurchVisitsReportUrls',
      setLoadingStatus: 'menu/setLoadingStatus',
      setRouterBackLink: 'user/setRouterBackLink',
      setSelectedChurchKey: 'user/setSelectedChurchKey',
      setSelectedVisitKey: 'churchMinistry/setSelectedVisitKey',
    }),
    async page_load() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          await this.loadAllVisitsByCamp({ limit: 9999, showall: false }),
          await this.filterVisits(),
          await this.loadChurchVisitsReportUrls(),
        ])
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async addVisit() {
      await Promise.all([
        await this.clearSingleVisit(),
        await this.setSelectedVisitKey(null),
        await this.setSelectedChurchKey(''),
      ]).then(() => {
        this.setRouterBackLink(this.$route.name)
        this.$router.push('/programs/cm/churchProfile/add-church-visit')
      })
    },
    async filterVisits() {
      if (
        !!this.userSelectedChurchKey &&
        !!this.$route.path.split('/')[4] &&
        this.$route.path.split('/')[4] !== 'menu'
      ) {
        this.visits = this.all_visits_by_camp.filter((fc) => fc.nte_entity_key === this.userSelectedChurchKey)
      } else {
        this.visits = this.all_visits_by_camp
      }
    },
    loadTranslations() {
      this.addItems.text = this.translations.tcAddVisit
      this.addItems.display = this.iCanSee(this.secured_controls.add_church_presentation_button)
      this.loadDataTableFields()
    },
    loadDataTableFields() {
      this.dataTableFields = [
        {
          key: 'contact_date',
          label: `${this.translations.tcDate}`,
          sortable: true,
          formatter: (value, key, item) => {
            return value.slice(0, 10)
          },
        },
        { key: 'church_name', label: `${this.translations.tcChurchName}`, sortable: true },
        { key: 'sort_name', label: `${this.translations.tcContactedBy}`, sortable: true },
        { key: 'nte_note', label: `${this.translations.tcNotes}`, sortable: true },
      ]
    },
  },
  computed: {
    ...mapGetters({
      all_visits_by_camp: 'churchMinistry/all_visits_by_camp',
      churchDetail: 'churchMinistry/churchDetail',
      churchVisitsByCampAsCSVUrl: 'churchMinistry/datalist1CSVUrl',
      churchVisitsByCampAsPDFUrl: 'churchMinistry/datalist1PDFUrl',
      iCanSee: 'user/iCanSee',
      userSelectedChurchKey: 'user/userSelectedChurchKey',
      prefCulture: 'user/userPreferredCulture',
    }),
  },
  components: {
    campSelector: CampSelect,
    dataTable: DataTable,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';

#top-of-site-pixel-anchor {
  position: absolute;
  width: 1px;
  height: 1px;
  top: 500px;
  left: 0;
}
#results {
  header {
    padding-bottom: 12px;
    @include breakpoint(sm) {
      padding-top: 23px;
      padding-left: 23px;
      padding-right: 23px;
    }
  }
  table {
    th,
    td {
      @include breakpoint(sm) {
        min-width: 0 !important;
        width: 25% !important;
      }
      &:nth-of-type(1) {
        width: 187px;
      }
      &:nth-of-type(2) {
        width: 311px;
      }
      &:nth-of-type(3) {
        width: 215px;
      }
      &:nth-of-type(4) {
        width: 447px;
        @include breakpoint(sm) {
          min-width: 400px !important;
        }
      }
    }
    td {
      vertical-align: top;
    }
  }
}
.church_name {
  margin: 20px 0 0 0;
}
</style>
